<template>
    <div
        v-if="isLoaded"
        class="statistics-wrapper overflow-hidden"
    >
        <HtEntitiesFiltersWrapper
            v-if="isLoaded"
            store="dashboard/statistics"
        >
            <template #title>
                <div class="title-wrapper">
                    <h1 class="title">
                        <t :firstname="shared.session.companyUser.firstname">
                            Hello {firstname}, how are you?
                        </t>
                    </h1>
                    <div class="reload-label">
                        <t :last-update="getLastUpdate">
                            Last update at {last-update}
                        </t>
                        <FontAwesomeIcon
                            icon="sync"
                            class="reload-button"
                            :class="refreshing"
                            transform="shrink-4"
                            @click="refresh"
                        />
                    </div>
                </div>
            </template>
            <template #topFilter>
                <HtButton
                    v-if="canOpenQuickActionsModal && isOldUi"
                    :icon="['fal', 'plus']"
                    type="secondary"
                    @click.native="openQuickActionModal"
                >
                    <t>Add</t>
                </HtButton>

                <HtFormSelector
                    id="programs"
                    v-model="programs"
                    name="programs"
                    :options="[...programOptions]"
                    :placeholder="translate('Programs')"
                    :selection-label="translate('{count} program | {count} programs',
                                                {count: programs.length})"
                    class="program-top-filter"
                />
            </template>
        </HtEntitiesFiltersWrapper>
        <div
            v-if="$canRead('AbstractProgramsDetailsWidget')"
            class="row"
        >
            <div class="col-md-4">
                <ActiveProgramsCountWidget />
            </div>
            <div class="col-md-4">
                <CompletionRatePercentageWidget />
            </div>
            <div class="col-md-4">
                <LateProgramsCountWidget />
            </div>
        </div>
        <div class="row">
            <div
                v-if="$canRead('AbstractNextKeyDatesWidget')"
                class="col-md-6"
            >
                <NextKeyDatesWidget />
            </div>
            <div
                v-if="$canRead('AbstractNotJoinedWidget')"
                class="col-md-6"
            >
                <NotJoinedWidget />
            </div>
            <!--            <div-->
            <!--                v-if="$canRead('AbstractMostActiveMembersWidget')"-->
            <!--                class="col-md-6"-->
            <!--            >-->
            <!--                <MostActiveMembersWidget />-->
            <!--            </div>-->
            <div
                v-if="$canRead('AbstractDeadlinesWidget')"
                class="col-md-6"
            >
                <DeadlinesWidget />
            </div>
        </div>
    </div>
</template>

<script>
import HtEntitiesFiltersWrapper from '@/components/globals/filters/HtEntitiesFiltersWrapper.vue';
import HtFormSelector from '@/components/globals/Selectors/HtFormSelector.vue';
import LateProgramsCountWidget from '@/components/pages/dashboard/widgets/LateProgramsCountWidget.vue';
import ActiveProgramsCountWidget from '@/components/pages/dashboard/widgets/ActiveProgramsCountWidget.vue';
import DeadlinesWidget from '@/components/pages/dashboard/widgets/DeadlinesWidget.vue';
import NotJoinedWidget from '@/components/pages/dashboard/widgets/NotJoinedWidget.vue';
import MostActiveMembersWidget from '@/components/pages/dashboard/widgets/MostActiveMembersWidget.vue';
import NextKeyDatesWidget from '@/components/pages/dashboard/widgets/NextKeyDatesWidget.vue';
import CompletionRatePercentageWidget from '@/components/pages/dashboard/widgets/CompletionRatePercentageWidget.vue';
import HtButton from '@/components/globals/HtButton.vue';
import ModalMixin from '@/components/globals/modals/modalMixin';
import QuickActionsModal from '@/components/globals/modals/QuickActions/QuickActionsModal.vue';

export default {
    permissions() {
        return [
            'AbstractDeadlinesWidget',
            'AbstractNotJoinedWidget',
            'AbstractMostActiveMembersWidget',
            'AbstractNextKeyDatesWidget',
            'AbstractProgramsDetailsWidget',
            'PageBulkEnrolling',
            'AbstractCanSelfEnroll',
        ];
    },

    components: {
        HtButton,
        HtEntitiesFiltersWrapper,
        HtFormSelector,
        LateProgramsCountWidget,
        ActiveProgramsCountWidget,
        DeadlinesWidget,
        NotJoinedWidget,
        MostActiveMembersWidget,
        NextKeyDatesWidget,
        CompletionRatePercentageWidget,
    },

    mixins: [
        ModalMixin,
    ],

    computed: {
        isLoaded() {
            return this.permissionsLoaded
                && this.$store.state.entities.entitiesLoaded
                && this.$store.state.programs.programsLoaded;
        },
        canOpenQuickActionsModal() {
            return this.$can('PageBulkEnrolling') || this.$canRead('AbstractCanSelfEnroll');
        },
        getLastUpdate() {
            return this.$store.state.dashboard.statistics.lastUpdate.format('LT');
        },
        refreshing() {
            return this.$store.getters['dashboard/statistics/loaded'] ? '' : 'fa-spin';
        },
        programOptions() {
            return this.$store.state.programs.programs.map((program) => ({
                id: program.id,
                name: this.oldLocalize(program.locales, 'name'),
            }));
        },
        programs: {
            /**
             * @param {array} programs
             * @returns {void}
             */
            set(programs) {
                const ids = programs.map((v) => v.id);

                this.$store.dispatch('dashboard/statistics/setProgramsFilter', ids);
            },
            /**
             * @returns {array}
             */
            get() {
                return this.$store.state.dashboard.statistics.filters.programs.map((id) => ({ id }));
            },
        },
    },

    created() {
        this.$store.dispatch('entities/fetchEntities');
        this.$store.dispatch('programs/fetchPrograms');
    },

    methods: {
        refresh() {
            this.$store.dispatch('dashboard/statistics/refresh');
        },

        openQuickActionModal() {
            this.openCenterModal(QuickActionsModal);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";
.statistics-wrapper{
    display: flex;
    flex-direction: column;
    gap: 24px;

    .program-top-filter{
        flex-grow: 1;
        ::v-deep .multiselect {
            width: 250px;
        }
    }
    .title-wrapper {
        display: flex;
        flex-direction: column;
        gap: 4px;
        & .reload-label {
            color: $neutral-600;
            font-weight: bold;
            font-size: 12px;
            & .reload-button {
                margin-left: 4px;
                cursor: pointer;
                &:hover {
                    color: var(--branding-color)
                }
            }
        }
    }

    .row {
        row-gap: 24px;
    }
}

@media (max-width: $desktop-small){
    .statistics-wrapper{
        .program-top-filter{
            ::v-deep .multiselect {
                width: 200px;
            }
        }
    }
}
</style>
