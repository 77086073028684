<template>
    <NewPageWithSidebar v-if="!isOldUi && companyUser && companyUser.isLoaded()">
        <template #title>
            <div
                v-if="newBackRoute"
                class="wrapper-back-btn"
            >
                <HtButton
                    class="back-btn"
                    size="small"
                    type="secondary"
                    :to="newBackRoute"
                >
                    <HtIcon name="arrow-left" />
                    <span class="back-btn-label"><t>Back</t></span>
                </HtButton>
            </div>
        </template>

        <ProfileHeader
            v-if="permissionsLoaded"
            :pages="items"
            :company-user="companyUser"
        />

        <router-view
            :key="$route.fullPath"
            :company-user="companyUser"
            :company-user-program-id="companyUser.company_user_program.models[0] ? Number(companyUser.company_user_program.models[0].id) : null"
            :company-user-id="Number(companyUserId)"
            @onReloadUser="loadCompanyUser"
        />
    </NewPageWithSidebar>
    <PageWithSidebar v-else-if="isOldUi && companyUser && companyUser.isLoaded()">
        <template #title>
            <HtPageTitle :back-route="backRoute">
                {{ companyUser.firstname }} {{ companyUser.lastname }}
            </HtPageTitle>
        </template>

        <template #sidebar>
            <HtPageSidebar
                v-if="permissionsLoaded"
                :pages="items"
            />
        </template>

        <router-view
            :key="$route.fullPath"
            :company-user="companyUser"
            :company-user-program-id="companyUser.company_user_program.models[0] ? Number(companyUser.company_user_program.models[0].id) : null"
            :company-user-id="Number(companyUserId)"
            @onReloadUser="loadCompanyUser"
        />
    </PageWithSidebar>
</template>

<script>
import profileEventBus from '@/eventBus/profileEventBus';
import HtPageSidebar from '@/components/globals/HtPageSidebar.vue';
import NewHtPageSidebar from '@/components/globals/NewHtPageSidebar.vue';
import CompanyUser from '@/models/CompanyUser';
import HtProgramType from '@/models/HtProgramType';
import HtPageTitle from '@/components/globals/HtPageTitle.vue';
import PageWithSidebar from '@/layouts/PageWithSidebar.vue';
import NewPageWithSidebar from '@/layouts/NewPageWithSidebar.vue';
import ProfileHeader from '@/components/pages/profile/ProfileHeader.vue';
import HtButton from '@/components/miscellaneous/HtButton.vue';
import { hasFeatureEnabled, FEATURE_NEW_UI } from '@/helpers/feature';

const pages = [
    {
        routeName: 'ProfileProgram',
        label: 'Programs',
        icon: 'cursor-pointer',
    },
    {
        routeName: 'Profile',
        label: 'Informations',
        icon: 'google-docs',
    },
    {
        routeName: 'ProfileAdministrative',
        label: 'Administrative',
        icon: 'scan-barcode',
    },
    {
        routeName: 'ProfileResources',
        label: 'Resources',
        icon: 'bookmark-book',
    },
    {
        routeName: 'ProfileActivities',
        label: 'Activities',
        icon: 'activity',
    },
    {
        routeName: 'ProfileDelegations',
        label: 'Delegations',
    },
];

export default {
    name: 'Profile',
    permissions: [
        'PageProfileProgram',
        'PageProfile',
        'PageProfileResources',
        'PageProfileAdministrative',
        'PageProfileActivities',
        'AbstractCanDelegate',
    ],

    components: {
        HtPageTitle,
        HtPageSidebar,
        NewHtPageSidebar,
        PageWithSidebar,
        NewPageWithSidebar,
        ProfileHeader,
        HtButton,
    },

    props: {
        companyUserId: {
            type: Number,
            required: true,
        },
        companyUserProgramId: {
            type: Number,
            required: false,
        },
    },

    data() {
        return {
            companyUser: null,
            newBackRoute: false,
        };
    },

    computed: {
        items() {
            const params = { company_user_id: this.companyUserId };

            const userHasNonDefaultProgram = this.companyUser && this.companyUser.company_user_program.models.some((userProgram) => userProgram.company_program.ht_program_type.slug !== HtProgramType.SLUG_DEFAULT && userProgram.status !== 'draft');

            if (this.permissionsLoaded) {
                const menuItems = pages
                    .filter((page) => {
                        if (page.routeName === 'ProfileDelegations') {
                            return this.$can('AbstractCanDelegate', params) && this.$store.getters['config/isFeatureEnabled']('delegation');
                        }
                        return this.$can(`Page${page.routeName}`, params);
                    })
                    // We only keep the onboarding menu if the user has a program that is not the default one
                    .filter((page) => page.routeName !== 'ProfileProgram' || userHasNonDefaultProgram)
                    .map((page) => {
                        const name = page.routeName === 'ProfileProgram' && Boolean(this.companyUserProgramId) ? 'ProfileProgramDetail' : page.routeName;
                        return {
                            name,
                            label: this.translate(page.label),
                            icon: page.icon,
                            params,
                        };
                    });
                if (hasFeatureEnabled(FEATURE_NEW_UI)) {
                    menuItems.push({
                        name: 'ProfileTeam',
                        label: this.translate('Team'),
                        icon: 'group',
                        params,
                    });
                }
                return menuItems;
            }

            return [];
        },

        backRoute() {
            const lastPage = this.$router.historyTrack[this.$router.historyTrack.length - 1];
            if (lastPage) {
                return { name: lastPage.name, params: lastPage.params };
            }

            return { name: 'ProgramDetailLive', params: { program_id: this.companyUser.company_user_program.models[0].company_program_id } };
        },
    },

    watch: {
        companyUserId() {
            this.loadCompanyUser();
            this.setNewBackRoute();
        },
    },

    mounted() {
        this.setNewBackRoute();
    },

    created() {
        this.loadCompanyUser();
        profileEventBus.$on('reload-user', this.loadCompanyUser);
    },

    beforeDestroy() {
        profileEventBus.$off('reload-user', this.loadCompanyUser);
    },

    methods: {
        loadCompanyUser() {
            this.companyUser = new CompanyUser([
                'id',
                'about',
                'arrival_date',
                'company_contract_id',
                'company_department_id',
                'company_file_id',
                'company_job_position_id',
                'company_user_program_id',
                'company_office_id',
                'date_of_birth',
                'email',
                'end_date',
                'firstname',
                'formatted_job_duties',
                'fullname',
                'gender',
                'ht_nationality_id',
                'image',
                'is_onboardee',
                'job_duties',
                'language',
                'lastname',
                'main_email',
                'personal_email',
                'phone_country_code',
                'phone_number',
                'professional_email',
                'timezone',
            ]).with({
                customFields: (query) => {
                    query.select([
                        'id',
                        'text',
                        'value',
                        'boolean',
                        'table_id',
                        'table_name',
                        'company_field_template_id',
                    ]).with({
                        template: (query) => {
                            query.select([
                                'id',
                                'type',
                                'name',
                                'field_is_editable',
                            ]);
                        },
                    }).whereHas({
                        template: (query) => {
                            query.where([
                                ['is_showable', '=', 1],
                            ]);
                        },
                    });
                },
                companyFile: (query) => {
                    query.select([
                        'id',
                        'original_name',
                        'detected_extension',
                        'uuid',
                    ]);
                },
                companyContract: (query) => {
                    query.select(['id', 'name', 'is_heyteam', 'has_end_date', 'has_probation']).withTrashed();
                },
                companyOffice: (query) => {
                    query.select(['id', 'name', 'country']).withTrashed();
                },
                companyDepartment: (query) => {
                    query.select(['id', 'name']).withTrashed();
                },
                companyJobPosition: (query) => {
                    query.select(['id', 'name']).withTrashed();
                },
                companyUserMetadata: (query) => {
                    query.select(['id', 'type', 'value', 'company_user_id']);
                },
                companyUserProgram: (query) => {
                    query.select(['id', 'company_program_id', 'status', 'activated_at']).with({
                        companyProgram: (query) => {
                            query.select(['id', 'ht_program_type_id']).with({
                                locales: (query) => {
                                    query.select([
                                        'id',
                                        'name',
                                        'language_key',
                                    ]);
                                },
                                keyDate: (query) => {
                                    query.select([
                                        'id',
                                        'company_program_id',
                                        'ht_program_key_date_id',
                                    ]);
                                    query.with({
                                        locales: (query) => {
                                            query.select([
                                                'id',
                                                'name',
                                                'language_key',
                                            ]).where([
                                                ['language_key', '=', this.shared.session.companyUser.company_language.key],
                                            ]);
                                        },
                                    });
                                },
                                htProgramType: (query) => {
                                    query.select(['id', 'slug']);
                                },
                            });
                        },
                        mainKeyDate: (query) => {
                            query.select(['id', 'starts_at']);
                        },
                        keyDates: (query) => {
                            query.select(['id', 'starts_at'])
                                .with({
                                    keyDate: (query) => {
                                        query.select(['id', 'is_main'])
                                            .with({
                                                locales: (query) => {
                                                    query.select(['id', 'name', 'language_key']);
                                                },
                                            });
                                    },
                                });
                        },
                    }).whereHas({
                        companyProgram: (query) => {
                            query.whereHas({
                                htProgramType: (query) => {
                                    query.where([
                                        ['slug', '!=', HtProgramType.SLUG_DEFAULT],
                                    ]);
                                },
                            });
                        },
                    });
                },
                defaultEntities: (query) => query.select([
                    'id',
                    'company_entity_value_id',
                    'company_entity_id',
                ]).with({
                    value: (query) => {
                        query.select([
                            'id',
                            'company_entity_id',
                            'resourceable_id',
                        ]).with({
                            custom: (query) => query.select(['id', 'key', 'name']),
                        });
                    },
                    entity: (query) => {
                        query.select([
                            'id',
                            'name',
                            'slug',
                            'is_private',
                            'is_heyteam',
                        ]);
                    },
                }),
            }).where([['id', '=', this.companyUserId]]);

            return this.companyUser.get().then((companyUser) => {
                if (!companyUser.id) {
                    this.$router.push('/Dashboard');
                }

                let page = null;

                if (this.shared.session.companyUser.id === this.companyUserId) {
                    page = {
                        avatar: {
                            image: companyUser.image,
                            firstname: companyUser.firstname,
                            lastname: companyUser.lastname,
                        },
                        title: companyUser.fullname,
                    };
                } else {
                    const iconAction = () => { this.$router.push(this.$Utils.getBackroute(this.$router)); };

                    page = {
                        avatar: {
                            image: companyUser.image,
                            firstname: companyUser.firstname,
                            lastname: companyUser.lastname,
                        },
                        icon: ['far', 'arrow-left'],
                        iconAction,
                        title: companyUser.fullname,
                        subtitles: companyUser.company_job_position ? [companyUser.company_job_position.name] : null,
                    };
                }

                this.setPage(page);

                if (this.shared.session.companyUser.id === companyUser.id) {
                    this.shared.session.companyUser.fill(companyUser);
                }

                return Promise.resolve();
            });
        },
        setNewBackRoute() {
            if (this.oldUi) return;
            const lastPage = this.$router.historyTrack[this.$router.historyTrack.length - 1];
            this.newBackRoute = lastPage.name ? { name: lastPage.name, params: lastPage.params } : false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

.wrapper-back-btn {
    margin-bottom: 12px;
    .back-btn {
        padding: 12px;
        ::v-deep .ht-button-inner {
            display: inline-flex;
            align-items: center;
            gap: 4px;
        }
        .back-btn-label {
            display: none;
        }
    }
}

@media (min-width: $tablet) {
    .wrapper-back-btn {
        .back-btn {
            padding: 6px 12px;
            .back-btn-label {
                display: block;
            }
        }
    }
}
</style>
