<template>
    <div class="wrapper-team">
        <h3 class="team-title">
            <t>Members of the team</t>
        </h3>
        <div class="wrapper-team-members">
            <div
                v-for="(supervisor, index) in supervisors"
                :key="index"
                class="team-member"
                @click="goToProfile(supervisor.user.id)"
            >
                <HtAvatar
                    class="team-member-avatar"
                    :user="$Utils.getAvatarData(supervisor.user)"
                    size="m"
                />
                <div class="team-member-body">
                    <div class="team-member-title">
                        {{ supervisor.user.firstname }} {{ supervisor.user.lastname }}
                    </div>
                    <div class="team-member-description">
                        {{ supervisor.role.is_heyteam ? translate(supervisor.role.alias) : supervisor.role.alias }}
                    </div>
                </div>
                <HtIcon
                    class="team-member-chat"
                    name="chat-bubble-empty"
                    size="16"
                    stroke-width="2"
                    @click.native.stop="onDiscuss(supervisor.user.id)"
                />
                <HtIcon
                    class="team-member-arrow"
                    name="nav-arrow-right"
                    size="24"
                />
            </div>
        </div>
        <div class="wrapper-rest-team">
            <router-link
                :to="{ name: 'Team', label: 'Team'}"
                class="rest-team"
            >
                <t>Discover the rest of my team</t>
            </router-link>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

const CHANNEL_TYPE_DUO = 'duo';

export default {
    name: 'NewMyTeamWidget',
    computed: {
        ...mapGetters({
            supervisors: 'dashboard/actions/getMyTeam',
        }),
    },
    methods: {
        goToProfile(id) {
            this.$router.push({
                name: 'Profile',
                params: { company_user_id: id },
            });
        },
        onDiscuss(userId) {
            const oldChannel = this.shared.socket.channelCollection.models.find((channel) => channel.type === CHANNEL_TYPE_DUO && channel.company_chat_channel_user.models.some((channelUser) => channelUser.company_user_id === userId));

            if (oldChannel) {
                this.shared.socket.channelCollection.setActive(oldChannel.id);
                this.shared.socket.chatOpened = true;
                return;
            }

            const channel = this.shared.socket.channelCollection.new();

            const channelUser1 = channel.company_chat_channel_user.new();
            channelUser1.company_user_id = userId;

            const channelUser2 = channel.company_chat_channel_user.new();
            channelUser2.company_user_id = this.shared.session.companyUser.id;

            channel.company_chat_channel_user.models.push(channelUser1);
            channel.company_chat_channel_user.models.push(channelUser2);
            channel.created_by_company_user_id = this.shared.session.companyUser.id;

            channel.save().then((data) => {
                const interlocutor = data.company_chat_channel_user.channelUserInterlocutor;
                interlocutor.company_user.isConnected = interlocutor.company_user_id in this.shared.socket.connectedUsers;
                this.shared.socket.channelCollection.setActive(data.id);
                this.shared.socket.chatOpened = true;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';
@import "~@/styles/ds/typography";

.wrapper-team {
    padding: 20px;
    border: 1px solid var(--border-primary);
    border-radius: var(--radius-block);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.03), 0px 0px 8px 0px rgba(16, 24, 40, 0.03);
    background-color: var(--fill-primary);
}
.team-title {
    @include ht-heading-4;
    margin: 0;
}
.wrapper-team-members {
    display: flex;
    flex-direction: column;
    margin: 20px 0 0;
}
.team-member {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    z-index: 1;
    &:not(:last-child) {
        margin-bottom: 12px;
    }
    &::before {
        content: '';
        position: absolute;
        top: -8px;
        bottom: -8px;
        left: -12px;
        right: -12px;
        background-color: var(--fill-hover);
        opacity: 0;
        border-radius: var(--radius);
        transform: scale(0.9);
        z-index: -1;
        transition:  var(--transition-s);
    }
    &:hover::before {
        opacity: 1;
        transform: scale(1);
    }
}
.team-member-avatar {
    flex: 0 0 auto;
    margin-right: 16px;
}
.team-member-body {
    min-width: 0;
    flex: 1 1 auto;
}
.team-member-title {
    @include ht-body;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.team-member-description {
    @include ht-caption;
    color: var(--text-secondary);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.team-member-chat {
    flex: 0 0 auto;
    margin-right: 2px;
}
.team-member-arrow {
    flex: 0 0 auto;
}
.wrapper-rest-team {
    display: flex;
    justify-content: center;
    margin: 32px 0 0;
    padding: 0 20px;
    text-align: center;
}
.rest-team {
    color: var(--text-primary);
    @include ht-body;
}
</style>
