<template>
    <div
        v-if="item && item.isLoaded() && permissionsLoaded"
        class="wrapper-main-footer"
    >
        <div class="modal-wrapper-main">
            <div class="wrapper-description">
                <DescLine v-if="shouldShowAvailabilityDate">
                    <template #title>
                        <t>Date</t>
                    </template>
                    <template #content>
                        {{
                            $Utils
                                .moment(item.company_user_program_task.datetime_end)
                                .format(
                                    (shared.session.companyUser.company_language.key === 'fr') ?
                                        'DD MMMM YYYY' : 'MMMM DD, YYYY'
                                )
                        }}
                    </template>
                </DescLine>
                <DescLine>
                    <template #title>
                        <t>Status</t>
                    </template>
                    <template #content>
                        <StatusLabel
                            :status="item.company_user_program_task.status"
                        />
                    </template>
                </DescLine>
            </div>
            <form
                class="form-2 spaced label-underline"
                data-cy="survey-form"
                @submit.prevent
            >
                <fieldset :class="{'mb-100': hasSupervisor.length === 0}">
                    <label>
                        <t>Participant:</t>
                    </label>
                    <div class="card-6 flex-container">
                        <div class="item">
                            <div
                                v-user-image="
                                    $Utils.getCompanyComponentRoleInitials(
                                        'user',
                                        1.25,
                                        {
                                            firstname:
                                                item.company_user_participant
                                                    .firstname,
                                            lastname:
                                                item.company_user_participant
                                                    .lastname,
                                            image:
                                                item.company_user_participant
                                                    .image
                                        }
                                    )
                                "
                                class="item-image role-only"
                                :class="'user'"
                            />
                            <div class="align-center">
                                {{ item.company_user_participant.firstname }}
                            </div>
                        </div>
                    </div>
                </fieldset>

                <fieldset
                    v-if="hasSupervisor.length > 0"
                    class="mb-100"
                >
                    <label>
                        <t>Watchers:</t>
                    </label>
                    <div class="card-6 flex-container">
                        <div
                            v-for="(watcher, index) in hasSupervisor"
                            :key="index"
                            class="item"
                        >
                            <div
                                v-user-image="
                                    $Utils.getCompanyComponentRoleInitials(
                                        watcher.company_user
                                            ? 'user'
                                            : watcher.company_role.alias_translated,
                                        1.25,
                                        watcher.company_user
                                            ? {
                                                firstname:
                                                    watcher.company_user
                                                        .firstname,
                                                lastname:
                                                    watcher.company_user.lastname,
                                                image: watcher.company_user.image
                                            }
                                            : null
                                    )
                                "
                                class="item-image role-only"
                                :class="
                                    watcher.company_user
                                        ? 'user'
                                        : watcher.company_role.is_removable
                                            ? ''
                                            : watcher.company_role.name
                                "
                            />
                            <div
                                v-if="
                                    watcher.company_user &&
                                        watcher.company_user.firstname
                                "
                                class="align-center"
                            >
                                {{ watcher.company_user.firstname }}
                            </div>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
        <div
            v-if="!isCancelled"
            class="modal-wrapper-footer"
        >
            <HtButton
                v-if="item.permissions.delete === true"
                type="destructive"
                size="large"
                data-cy="survey-delete-button"
                @click="onDelete()"
            >
                <t>Remove</t>
            </HtButton>
            <HtButton
                v-if="item.permissions.update === true"
                type="primary"
                size="large"
                data-cy="survey-edit-button"
                @click="openEditModal()"
            >
                <t>Edit</t>
            </HtButton>
            <HtButton
                v-if="item.company_user_program_task.status === 'done'"
                type="primary"
                size="large"
                data-cy="survey-see-button"
                @click="openResultModal()"
            >
                <t>See results</t>
            </HtButton>
            <HtButton
                v-if="canAnswer"
                type="primary"
                size="large"
                data-cy="survey-answer-button"
                @click="openChatModal()"
            >
                <t>Answer</t>
            </HtButton>
        </div>
        <modalable
            ref="modalableUserEdit"
            class="modalable-1 medium"
            :mode="2"
        >
            <UserSurveyEdit
                :id="id"
                ref="userEdit"
                :company-user-id="companyUserId"
                :intelligent-selection="intelligentSelection"
                @onUpdate="onUpdate(...arguments)"
                @onDelete="onRemove(...arguments)"
            />
        </modalable>

        <modalable
            ref="modalableSurveyResult"
            class="modalable-1 big"
            :mode="2"
        >
            <UserSurveyResult
                :id="id"
                ref="surveyResult"
                :company-user-id="companyUserId"
            />
        </modalable>
    </div>
</template>

<script>
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import DescLine from '@/components/pages/dashboard/modals/DescLine.vue';
import StatusLabel from '@/components/pages/dashboard/modals/StatusLabel.vue';
import HtButton from '@/components/miscellaneous/HtButton.vue';
import UserSurveyResult from './UserSurveyResult.vue';
import UserSurveyEdit from './UserSurveyEdit.vue';
import CompanyUserSurvey from '../../../../../models/CompanyUserSurvey';
import CompanyUserSurveyCollection from '../../../../../models/CompanyUserSurveyCollection';

export default {
    name: 'NewUserSurveyItem',
    permissions: [
        'ModelCompanyUserSurveyQuestion',
    ],

    components: {
        UserSurveyEdit,
        UserSurveyResult,
        DescLine,
        StatusLabel,
        HtButton,
    },

    inject: ['modal'],

    props: {
        companyUserId: { type: [String, Number], required: true },
        intelligentSelection: { type: Array, required: false },
        value: { type: CompanyUserSurvey, required: false },
        collection: { type: CompanyUserSurveyCollection, required: false },
        id: { type: [String, Number], required: false },
        shouldShowAvailabilityDate: { type: Boolean, default: () => true },
    },

    data() {
        return {
            item: null,
            companyUserSurvey: new CompanyUserSurvey([
                'id', 'name', 'company_survey_id', 'company_user_id', 'company_chat_channel_id', 'company_user_participant_id', 'permissions',
            ]).with({
                companyUserProgramTask: (query) => {
                    query.select(['id', 'status', 'datetime_end']);
                },
                companySurvey: (query) => {
                    query
                        .select([
                            'id',
                        ])
                        .with({
                            resource: (query) => {
                                query
                                    .select([
                                        'id',
                                        'name',
                                    ]);
                            },
                        });
                },
                companyUser: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'arrival_date', 'image'])
                        .with({
                            companyLanguage: (query) => {
                                query.select(['id', 'key']);
                            },
                        });
                },
                companyUserParticipant: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'image']);
                },
                companyUserSurveySupervisor: (query) => {
                    query.select(['id', 'company_user_id', 'company_role_id']).with({
                        companyUser: (query) => {
                            query.select(['id', 'firstname', 'lastname', 'image']);
                        },
                    });
                },
            }).where([['id', '=', this.id]]),
        };
    },

    computed: {
        hasSupervisor() {
            return this.item.company_user_survey_supervisor.models.filter(
                (supervisor) => supervisor.company_user || supervisor.company_role,
            );
        },

        canSeeResult() {
            return this.item.company_user_program_task.status === CompanyUserProgramTask.STATUS_DONE;
        },

        canAnswer() {
            return this.$can(
                'ModelCompanyUserSurvey',
                this.item,
                'startSurvey',
            );
        },

        isCancelled() {
            return this.item.company_user_program_task.status === CompanyUserProgramTask.STATUS_CANCELLED;
        },
    },

    created() {
        if (this.value) {
            this.item = this.value.fromSaveState();
            this.modal.setTitle(this.item.name);
            this.modal.setSubtitle(this.translate('Survey'));
        } else {
            this.companyUserSurvey.company_user_id = this.companyUserId;
            this.companyUserSurvey.get().then(() => {
                this.item = this.companyUserSurvey;
                this.modal.setTitle(this.item.name);
                this.modal.setSubtitle(this.translate('Survey'));
            }).catch(() => {
                this.modal.close();
            });
        }
    },

    shared: {
        socket: {
        },
    },

    methods: {
        onUpdate() {
            this.modal.close();
            this.$emit('onUpdate');
        },

        onRemove() {
            this.modal.close();
            this.$emit('onDelete');
        },

        openEditModal() {
            this.$refs.modalableUserEdit.open();
        },

        openChatModal() {
            if (this.shared.socket.connectedUsers === undefined) return;

            this.modal.close();

            this.shared.socket.chatListChannelComponent.setActive(this.item.company_chat_channel_id).then(() => {
                this.shared.socket.chatOpened = true;
            });
        },

        openResultModal() {
            this.$refs.modalableSurveyResult.open();
        },

        onDelete() {
            this.modal.deleteWindow({ content: this.translate('Are you sure you want to delete this survey? Once you click on delete, you will no longer be able to access this survey.') }).then((result) => {
                if (result) {
                    this.item.delete().then(() => {
                        this.onRemove();
                    });
                }
            });
        },
    },
};
</script>

<style lang="scss">
@import "~@/styles/var";
.survey-answer-modal {
    position: fixed !important;
}
@media (max-width: $tablet) {
    .modalable-1 {
        &.survey-answer-modal,
        &.large.survey-answer-modal {
            .modal {
                .header-modal {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    .close-modal {
                        background-color: white;
                    }
                }
                .modal-container {
                    .modal-content {
                        padding: 0;
                    }
                }
            }
        }
    }
}
</style>
<style lang="scss" scoped>
.item {
    cursor: default !important;
}

.mb-100 {
    margin-bottom: 100px !important;
}
</style>
