<template>
    <div class="wrapper-main-footer">
        <template v-if="companyUserDoc.isLoaded() && !hasError">
            <div class="modal-wrapper-main">
                <div class="wrapper-description">
                    <DescLine v-if="shouldShowAvailabilityDate && companyUserDoc.company_user_program_task">
                        <template #title>
                            <t>Date</t>
                        </template>
                        <template #content>
                            {{ $Utils.moment(companyUserDoc.company_user_program_task.datetime_end).format((shared.session.companyUser.company_language.key === 'fr') ? 'DD MMMM YYYY' : 'MMMM DD, YYYY') }}
                        </template>
                    </DescLine>
                    <DescLine>
                        <template #title>
                            <t>Status</t>
                        </template>
                        <template #content>
                            <StatusLabel
                                :status="companyUserDoc.company_user_program_task.status"
                            />
                        </template>
                    </DescLine>
                    <DescLine v-if="companyUserDoc.description">
                        <template #title>
                            <t>Description</t>
                        </template>
                        <template #content>
                            <p v-dompurify-html="companyUserDoc.description" />
                        </template>
                    </DescLine>
                    <DescLine v-if="companyUserDoc.specific_information">
                        <template #title>
                            <t>Specific information</t>
                        </template>
                        <template #content>
                            <p v-dompurify-html="companyUserDoc.specific_information" />
                        </template>
                    </DescLine>
                </div>
                <div v-if="companyUserDoc.company_file">
                    <NewDownloadable :company-file="companyUserDoc.company_file" />
                </div>
            </div>
            <div
                v-if="!isCancelled"
                class="modal-wrapper-footer"
            >
                <HtButton
                    v-if="companyUserDoc.permissions.delete === true"
                    type="destructive"
                    size="large"
                    data-cy="document-delete-button"
                    @click="onDelete()"
                >
                    <t>Remove</t>
                </HtButton>
                <HtButton
                    v-if="companyUserDoc.permissions.update === true"
                    type="primary"
                    size="large"
                    data-cy="document-edit-button"
                    @click="openEditModal()"
                >
                    <t>Edit</t>
                </HtButton>
                <HtButton
                    v-if="companyUserDoc.company_user_program_task.permissions.can_validate && canValidate"
                    type="primary"
                    size="large"
                    @click="toggleStatus()"
                >
                    {{ toggleButtonLabel }}
                </HtButton>
            </div>
            <modalable
                ref="modalableUserEdit"
                class="modalable-1 medium"
                :mode="2"
            >
                <UserDocEdit
                    :id="id"
                    ref="userEdit"
                    :company-user-id="companyUserId"
                    :should-show-availability-date="shouldShowAvailabilityDate"
                    @onUpdate="onUpdate()"
                    @onDelete="onDelete(true)"
                />
            </modalable>
        </template>
        <template v-if="hasError">
            <ErrorDisplay :append-errors="'This resource is not accessible'" />
        </template>
    </div>
</template>
<script>
import CompanyUserDoc from '@/models/CompanyUserDoc';
import UtilsCompanyFile from '@/models/common/utils/UtilsCompanyFile';
import DescLine from '@/components/pages/dashboard/modals/DescLine.vue';
import StatusLabel from '@/components/pages/dashboard/modals/StatusLabel.vue';
import HtButton from '@/components/miscellaneous/HtButton.vue';
import UserDocEdit from './UserDocEdit.vue';
import CompanyUserProgramTask from '../../../../../models/CompanyUserProgramTask';

export default {
    name: 'NewUserDocItem',
    components: {
        UserDocEdit,
        DescLine,
        StatusLabel,
        HtButton,
    },
    inject: ['modal'],

    props: {
        id: {
            type: Number,
            required: true,
        },
        companyUserId: {
            type: Number,
            required: true,
        },
        statusOnToggle: {
            type: String,
            default: CompanyUserProgramTask.STATUS_PENDING,
        },
        shouldShowAvailabilityDate: {
            type: Boolean,
            required: false,
            default: () => true,
        },
        canValidate: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            hasError: false,

            companyUserDoc: new CompanyUserDoc([
                'id',
                'company_user_id',
                'name',
                'description',
                'specific_information',
                'permissions',
            ])
                .with({
                    companyUser: (query) => {
                        query.select(['id', 'firstname']);
                    },
                    companyUserProgramTask: (query) => {
                        query.select([
                            'id',
                            'resource',
                            'status',
                            'company_user_id',
                            'datetime_start',
                            'datetime_end',
                            'permissions',
                            'validator_type',
                        ]);
                    },
                    companyFile: (query) => {
                        query.select(UtilsCompanyFile.allowedFullFields());
                    },
                })
                .where([
                    ['id', '=', this.id],
                ]),
        };
    },

    computed: {
        /**
         * @returns {boolean}
         */
        isDone() {
            return this.companyUserDoc.company_user_program_task.status === CompanyUserProgramTask.STATUS_DONE;
        },

        labelTitle() {
            return this.translate(this.companyUserDoc.name);
        },

        toggleButtonLabel() {
            return this.translate(this.isDone ? 'Mark as unread' : 'Mark as read');
        },

        isCancelled() {
            return this.companyUserDoc.company_user_program_task.status === CompanyUserProgramTask.STATUS_CANCELLED;
        },

    },

    watch: {
        'item._state.isSaving': function (val) {
            this.button_state = val ? 'loading' : 'idle';
        },
    },

    created() {
        this.companyUserDoc.get().then(
            () => {
                this.modal.setTitle(this.labelTitle);
                this.modal.setSubtitle(this.translate('Document'));
            },
            () => this.hasError = true,
        );
    },

    methods: {
        onUpdate() {
            this.modal.close();
            this.$emit('onUpdate');
        },

        onDelete(fromEdit = false) {
            if (fromEdit === true) {
                this.modal.close();
                this.$emit('onDelete');
            } else {
                this.modal.deleteWindow({ content: this.translate('Are you sure you want to delete this document? Once you click on delete, you will no longer be able to access this document.') }).then(
                    (response) => {
                        if (response === true) {
                            this.companyUserDoc.delete().then(
                                () => {
                                    this.modal.close();
                                    this.$emit('onDelete');
                                },
                            );
                        }
                    },
                );
            }
        },

        openEditModal() {
            this.$refs.modalableUserEdit.open();
        },

        async toggleStatus() {
            await this.$handleValidationTasks({
                id: this.companyUserDoc.company_user_program_task.id,
                status: this.isDone ? CompanyUserProgramTask.STATUS_PENDING : CompanyUserProgramTask.STATUS_DONE,
            });
            this.modal.close();
            this.$emit('onUpdate');
        },

    },
};
</script>
