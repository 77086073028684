<template>
    <div
        v-if="companyUserEquipment.isLoaded()"
        class="wrapper-main-footer"
    >
        <div class="modal-wrapper-main">
            <div class="wrapper-description">
                <DescLine>
                    <template #title>
                        <t>Setup date</t>
                    </template>
                    <template #content>
                        {{
                            $Utils
                                .moment(companyUserEquipment.company_user_program_task.datetime_end)
                                .format(
                                    (shared.session.companyUser.company_language.key === 'fr') ?
                                        'DD MMMM YYYY' : 'MMMM DD, YYYY'
                                )
                        }}
                    </template>
                </DescLine>
                <DescLine>
                    <template #title>
                        <t>Status</t>
                    </template>
                    <template #content>
                        <StatusLabel
                            :status="companyUserEquipment.company_user_program_task.status"
                        />
                    </template>
                </DescLine>
                <DescLine v-if="companyUserEquipment.specific_information">
                    <template #title>
                        <t>Specific information</t>
                    </template>
                    <template #content>
                        <p>{{ companyUserEquipment.specific_information }}</p>
                    </template>
                </DescLine>
            </div>
        </div>
        <div class="modal-wrapper-footer">
            <HtButton
                v-if="companyUserEquipment.permissions.delete && !isCancelled"
                type="destructive"
                size="large"
                data-cy="equipment-remove-button"
                @click="remove()"
            >
                <t>Remove</t>
            </HtButton>
            <HtButton
                v-if="companyUserEquipment.permissions.update && !isCancelled"
                type="primary"
                size="large"
                data-cy="equipment-edit-button"
                @click="edit()"
            >
                <t>Edit</t>
            </HtButton>
            <HtButton
                v-if="companyUserEquipment.company_user_program_task.permissions.can_validate && canValidate"
                type="primary"
                size="large"
                data-cy="equipment-toggle-status-button"
                :loading="isButtonLoading"
                @click="toggleStatus()"
            >
                <t>{{ isDone ? 'Mark as undone' : 'Mark as done' }}</t>
            </HtButton>
        </div>
        <Modalable
            ref="modal"
            class="modalable-1 medium"
            :mode="2"
        >
            <UserEquipmentEdit
                :id="id"
                :company-user-id="companyUserId"
                @onUpdate="onUpdate"
                @onDelete="onDelete"
            />
        </Modalable>
    </div>
</template>

<script>
import CompanyUserEquipment from '@/models/CompanyUserEquipment';
import DescLine from '@/components/pages/dashboard/modals/DescLine.vue';
import StatusLabel from '@/components/pages/dashboard/modals/StatusLabel.vue';
import HtButton from '@/components/miscellaneous/HtButton.vue';
import UserEquipmentEdit from './UserEquipmentEdit.vue';
import CompanyUserProgramTask from '../../../../../models/CompanyUserProgramTask';

export default {
    name: 'NewUserEquipmentItem',
    components: {
        UserEquipmentEdit,
        DescLine,
        StatusLabel,
        HtButton,
    },
    inject: ['modal'],
    props: {
        id: {
            type: Number,
            required: true,
        },
        companyUserId: {
            type: Number,
            required: true,
        },
        canValidate: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            buttonState: 'idle',

            companyUserEquipment: new CompanyUserEquipment([
                'id',
                'company_user_id',
                'company_equipment_id',
                'specific_information',
                'permissions',
            ]).with({
                companyEquipment: (query) => {
                    query
                        .select(['id', 'image'])
                        .withTrashed()
                        .with({
                            resource: (query) => {
                                query.select(['name']);
                            },
                        });
                },
                companyUser: (query) => {
                    query.select(['id', 'firstname'])
                        .with({
                            companyLanguage: (query) => {
                                query.select(['id', 'key']);
                            },
                        });
                },
                companyUserProgramTask: (query) => {
                    query.select([
                        'id',
                        'resource',
                        'company_user_id',
                        'status',
                        'datetime_start',
                        'datetime_end',
                        'permissions',
                    ]);
                },
            }).where([
                ['id', '=', this.id],
            ]),
        };
    },

    computed: {
        isDone() {
            return this.companyUserEquipment.company_user_program_task.status === CompanyUserProgramTask.STATUS_DONE;
        },

        labelTitle() {
            return this.companyUserEquipment.name;
        },

        isCancelled() {
            return this.companyUserEquipment.company_user_program_task.status === CompanyUserProgramTask.STATUS_CANCELLED;
        },
    },

    watch: {
        'companyUserEquipment._state.isSaving': function (value) {
            this.buttonState = value ? 'loading' : 'idle';
        },
    },

    created() {
        this.companyUserEquipment.company_user_id = this.companyUserId;
        this.companyUserEquipment.get()
            .then(() => {
                this.modal.setTitle(this.labelTitle);
                this.modal.setSubtitle(this.translate('Equipment'));
            });
    },

    methods: {
        edit() {
            this.$refs.modal.open();
        },

        /**
             * validate or invalidate the associated task
             */
        async toggleStatus() {
            await this.$handleValidationTasks({
                id: this.companyUserEquipment.company_user_program_task.id,
                status: this.isDone ? CompanyUserProgramTask.STATUS_PENDING : CompanyUserProgramTask.STATUS_DONE,
            });
            this.$emit('onUpdate');
            this.modal.close();
        },

        remove() {
            this.modal.deleteWindow({ content: this.translate('Are you sure you want to delete this equipment? Once you click on delete, you will no longer be able to access this equipment.') }).then((result) => {
                if (result) {
                    this.companyUserEquipment.delete().then(() => this.onDelete());
                }
            });
        },

        onUpdate() {
            this.modal.close();
            this.$emit('onUpdate');
        },

        onDelete() {
            this.modal.close();
            this.$emit('onDelete');
        },
    },
};
</script>
