<template>
    <div v-if="!isOldUi">
        <template v-if="companyUserProgramCollection.isLoaded()">
            <div v-if="companyUserProgramId">
                <div class="profile-program-header">
                    <HtButton
                        v-if="hasMultiplePrograms"
                        class="back-btn"
                        size="small"
                        type="secondary"
                        @click="onChangeViewProgram()"
                    >
                        <HtIcon name="arrow-left" />
                        <span class="back-btn-label"><t>Back</t></span>
                    </HtButton>
                    <div class="profile-program-title">
                        {{ getTitle }}
                    </div>
                    <HtSelect
                        v-if="hasMultiplePrograms"
                        v-model="currentProgramView"
                        :label="translate('Display')"
                        :options="programViewList"
                        @input="onChangeViewProgram"
                    />
                </div>
                <ActionProgramDetail
                    :company-user-id="companyUserId"
                    :company-user-program-id="companyUserProgramId"
                />
            </div>
            <NewProfileAllProgram
                v-else
                :company-user-program-collection="companyUserProgramCollection"
                @on-change-program="onChangeViewProgram"
            />
        </template>
        <template v-else>
            <div class="ht-loading-wrapper">
                <HtIconLoading />
            </div>
        </template>
    </div>
    <HtCard v-else-if="isOldUi && companyUserProgramCollection.isLoaded()">
        <template #title>
            <div>{{ getTitle }}</div>
            <HtSelectSimple
                :value="currentProgramView"
                :label="translate('Display')"
                :options="programViewList"
                @input="onChangeViewProgram"
            />
        </template>
        <template #default>
            <ProfileDetailProgram
                v-if="companyUserProgramId"
                :company-user-id="companyUserId"
                :company-user-program-id="companyUserProgramId"
                :program-label="getTitle"
                :program="getProgram"
            />
            <ProfileAllProgram
                v-else
                :company-user-program-collection="companyUserProgramCollection"
                @on-change-program="onChangeViewProgram"
            />
        </template>
    </HtCard>
</template>

<script>
import HtProgramType from '@/models/HtProgramType';
import HtSelectSimple from '@/components/globals/HtSelectSimple.vue';
import CompanyUserProgramCollection from '@/models/CompanyUserProgramCollection';
import CompanyUserProgram from '@/models/CompanyUserProgram';
import ActionProgramDetail from '@/components/pages/actions/program/ActionProgramDetail.vue';
import HtButton from '@/components/miscellaneous/HtButton.vue';
import ProfileAllProgram from './programs/ProfileAllProgram.vue';
import NewProfileAllProgram from './programs/NewProfileAllProgram.vue';
import ProfileDetailProgram from './programs/ProfileDetailProgram.vue';

export default {
    name: 'ProfileProgram',
    components: {
        HtSelectSimple,
        ProfileAllProgram,
        NewProfileAllProgram,
        ProfileDetailProgram,
        ActionProgramDetail,
        HtButton,
    },

    props: {
        companyUserId: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            minimumNumberOfPrograms: 2,
            companyUserProgramId: null,
            currentProgramView: {
                label: this.translate('All programs'),
                value: null,
            },
            programViewList: [
                {
                    label: this.translate('All programs'),
                    value: null,
                },
            ],
            companyUserProgramCollection: new CompanyUserProgramCollection([
                'id',
                'company_user_id',
                'title',
                'description',
                'type',
                'status',
                'timelines',
                'enrollee_completion',
                'participants_completion',
                'task_count_late',
                'company_program_id',
            ]).with({
                mainKeyDate: (query) => {
                    query.select(['id', 'starts_at']);
                },
                companyProgram: (query) => {
                    query.select(['id']).with({
                        locales: (query) => {
                            query.select(['id', 'language_key', 'name']);
                        },
                    });
                },
            }).where([
                ['company_user_id', '=', this.companyUserId],
            ]).whereIn([
                ['status', [CompanyUserProgram.STATUS_ACTIVE, CompanyUserProgram.STATUS_COMPLETED]],
            ])
                .whereHas({
                    companyProgram: (query) => {
                        query.whereHas({
                            htProgramType: (query) => {
                                query.where([
                                    ['slug', '!=', HtProgramType.SLUG_DEFAULT],
                                ]);
                            },
                        });
                    },
                }),
        };
    },

    computed: {
        hasMultiplePrograms() {
            return this.programViewList.length > this.minimumNumberOfPrograms;
        },
        getTitle() {
            if (this.companyUserProgramId) {
                const program = this.getProgram;
                if (program) {
                    return program.name_translated;
                }
                return '';
            }
            return this.translate('{count} Program | {count} Programs', { count: this.companyUserProgramCollection.$.models.length });
        },
        getProgram() {
            if (this.companyUserProgramId) {
                const currentUserProgram = this.companyUserProgramCollection.$.models.find((userProgram) => userProgram.id === this.companyUserProgramId);
                return (currentUserProgram ? currentUserProgram.company_program : null);
            }
            return null;
        },
    },

    created() {
        this.companyUserProgramCollection.get().then(() => {
            if (this.companyUserProgramCollection.$.models.length === 1) {
                this.onChangeViewProgram({ value: this.companyUserProgramCollection.$.models[0].id });
            } else {
                this.companyUserProgramCollection.$.models.forEach((userProgram) => {
                    this.programViewList.push({
                        label: userProgram.company_program.name_translated,
                        value: userProgram.id,
                    });
                });

                this.onChangeViewProgram({ value: Number(this.$route.params.company_user_program_id) });
            }
        }).catch(() => {
            this.$router.push({
                name: 'Profile',
                params: {
                    company_user_id: this.company_user_id,
                },
            });
        });
    },

    methods: {
        /**
         * @param {Object} program - le programme à afficher
         * @returns {void}
         */

        onChangeViewProgram(program = {}) {
            const selectedUserProgramId = (program.value) ? program.value : null;
            this.$set(this, 'companyUserProgramId', selectedUserProgramId);
            this.currentProgramView = this.programViewList.find((userProgram) => userProgram.value === this.companyUserProgramId);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/ds/typography";

.ht-loading-wrapper {
    padding: 16px;
    border-radius: var(--radius-block);
    background-color: var(--fill-secondary);
}

.profile-program-header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 20px;
}
.profile-program-title {
    @include ht-heading-3;
    margin-right: auto;
}
.back-btn {

    ::v-deep .ht-button-inner {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }
    }
</style>
