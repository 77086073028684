<template>
    <NewPageWithSidebar v-if="!isOldUi">
        <template #sidebar>
            <NewHtPageSidebar :pages="items" />
        </template>

        <router-view />
    </NewPageWithSidebar>
    <PageWithSidebar v-else-if="isOldUi">
        <template #sidebar>
            <HtPageSidebar :pages="items" />
        </template>

        <router-view />
    </PageWithSidebar>
</template>

<script>
import HtPageSidebar from '@/components/globals/HtPageSidebar.vue';
import NewHtPageSidebar from '@/components/globals/NewHtPageSidebar.vue';
import PageWithSidebar from '@/layouts/PageWithSidebar.vue';
import NewPageWithSidebar from '@/layouts/NewPageWithSidebar.vue';

export default {
    name: 'HoldingSettings',
    components: {
        HtPageSidebar,
        NewHtPageSidebar,
        PageWithSidebar,
        NewPageWithSidebar,
    },
    data() {
        return {
            items: [
                {
                    label: this.translate('Users'),
                    name: 'HoldingSettingsUsersCount',
                },
            ],
        };
    },
};
</script>
