<template>
    <div>
        <History v-if="isOldUi" />
        <NewHistory v-else />
    </div>
</template>
<script>
import History from './History.vue';
import NewHistory from './NewHistory.vue';

export default {
    name: 'HistoryPage',
    components: {
        History,
        NewHistory,
    },
};
</script>
