var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.item && _vm.item.isLoaded() && _vm.permissionsLoaded)?_c('div',{staticClass:"wrapper-main-footer"},[_c('div',{staticClass:"modal-wrapper-main"},[_c('div',{staticClass:"wrapper-description"},[(_vm.shouldShowAvailabilityDate)?_c('DescLine',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('t',[_vm._v("Date")])]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$Utils .moment(_vm.item.company_user_program_task.datetime_end) .format( (_vm.shared.session.companyUser.company_language.key === 'fr') ? 'DD MMMM YYYY' : 'MMMM DD, YYYY' ))+" ")]},proxy:true}],null,false,711341075)}):_vm._e(),_c('DescLine',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('t',[_vm._v("Status")])]},proxy:true},{key:"content",fn:function(){return [_c('StatusLabel',{attrs:{"status":_vm.item.company_user_program_task.status}})]},proxy:true}],null,false,4278744121)})],1),_c('form',{staticClass:"form-2 spaced label-underline",attrs:{"data-cy":"survey-form"},on:{"submit":function($event){$event.preventDefault();}}},[_c('fieldset',{class:{'mb-100': _vm.hasSupervisor.length === 0}},[_c('label',[_c('t',[_vm._v("Participant:")])],1),_c('div',{staticClass:"card-6 flex-container"},[_c('div',{staticClass:"item"},[_c('div',{directives:[{name:"user-image",rawName:"v-user-image",value:(
                                _vm.$Utils.getCompanyComponentRoleInitials(
                                    'user',
                                    1.25,
                                    {
                                        firstname:
                                            _vm.item.company_user_participant
                                                .firstname,
                                        lastname:
                                            _vm.item.company_user_participant
                                                .lastname,
                                        image:
                                            _vm.item.company_user_participant
                                                .image
                                    }
                                )
                            ),expression:"\n                                $Utils.getCompanyComponentRoleInitials(\n                                    'user',\n                                    1.25,\n                                    {\n                                        firstname:\n                                            item.company_user_participant\n                                                .firstname,\n                                        lastname:\n                                            item.company_user_participant\n                                                .lastname,\n                                        image:\n                                            item.company_user_participant\n                                                .image\n                                    }\n                                )\n                            "}],staticClass:"item-image role-only",class:'user'}),_c('div',{staticClass:"align-center"},[_vm._v(" "+_vm._s(_vm.item.company_user_participant.firstname)+" ")])])])]),(_vm.hasSupervisor.length > 0)?_c('fieldset',{staticClass:"mb-100"},[_c('label',[_c('t',[_vm._v("Watchers:")])],1),_c('div',{staticClass:"card-6 flex-container"},_vm._l((_vm.hasSupervisor),function(watcher,index){return _c('div',{key:index,staticClass:"item"},[_c('div',{directives:[{name:"user-image",rawName:"v-user-image",value:(
                                _vm.$Utils.getCompanyComponentRoleInitials(
                                    watcher.company_user
                                        ? 'user'
                                        : watcher.company_role.alias_translated,
                                    1.25,
                                    watcher.company_user
                                        ? {
                                            firstname:
                                                watcher.company_user
                                                    .firstname,
                                            lastname:
                                                watcher.company_user.lastname,
                                            image: watcher.company_user.image
                                        }
                                        : null
                                )
                            ),expression:"\n                                $Utils.getCompanyComponentRoleInitials(\n                                    watcher.company_user\n                                        ? 'user'\n                                        : watcher.company_role.alias_translated,\n                                    1.25,\n                                    watcher.company_user\n                                        ? {\n                                            firstname:\n                                                watcher.company_user\n                                                    .firstname,\n                                            lastname:\n                                                watcher.company_user.lastname,\n                                            image: watcher.company_user.image\n                                        }\n                                        : null\n                                )\n                            "}],staticClass:"item-image role-only",class:watcher.company_user
                                    ? 'user'
                                    : watcher.company_role.is_removable
                                        ? ''
                                        : watcher.company_role.name}),(
                                watcher.company_user &&
                                    watcher.company_user.firstname
                            )?_c('div',{staticClass:"align-center"},[_vm._v(" "+_vm._s(watcher.company_user.firstname)+" ")]):_vm._e()])}),0)]):_vm._e()])]),(!_vm.isCancelled)?_c('div',{staticClass:"modal-wrapper-footer"},[(_vm.item.permissions.delete === true)?_c('HtButton',{attrs:{"type":"destructive","size":"large","data-cy":"survey-delete-button"},on:{"click":function($event){return _vm.onDelete()}}},[_c('t',[_vm._v("Remove")])],1):_vm._e(),(_vm.item.permissions.update === true)?_c('HtButton',{attrs:{"type":"primary","size":"large","data-cy":"survey-edit-button"},on:{"click":function($event){return _vm.openEditModal()}}},[_c('t',[_vm._v("Edit")])],1):_vm._e(),(_vm.item.company_user_program_task.status === 'done')?_c('HtButton',{attrs:{"type":"primary","size":"large","data-cy":"survey-see-button"},on:{"click":function($event){return _vm.openResultModal()}}},[_c('t',[_vm._v("See results")])],1):_vm._e(),(_vm.canAnswer)?_c('HtButton',{attrs:{"type":"primary","size":"large","data-cy":"survey-answer-button"},on:{"click":function($event){return _vm.openChatModal()}}},[_c('t',[_vm._v("Answer")])],1):_vm._e()],1):_vm._e(),_c('modalable',{ref:"modalableUserEdit",staticClass:"modalable-1 medium",attrs:{"mode":2}},[_c('UserSurveyEdit',{ref:"userEdit",attrs:{"id":_vm.id,"company-user-id":_vm.companyUserId,"intelligent-selection":_vm.intelligentSelection},on:{"onUpdate":function($event){return _vm.onUpdate(...arguments)},"onDelete":function($event){return _vm.onRemove(...arguments)}}})],1),_c('modalable',{ref:"modalableSurveyResult",staticClass:"modalable-1 big",attrs:{"mode":2}},[_c('UserSurveyResult',{ref:"surveyResult",attrs:{"id":_vm.id,"company-user-id":_vm.companyUserId}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }