<template>
    <IndexLayout>
        <template #headbar>
            <HeadBar v-if="isOldUi" />
            <HtMobileMainBar v-else-if="isMobile" />
            <HtMainBar v-else />
        </template>
        <template #extra>
            <ChatModal v-if="shared.config.allowChat" />
            <ChatNotification v-if="shared.config.allowChat" />
        </template>
    </IndexLayout>
</template>

<script>
import ChatModal from '@/components/chat/ChatModal.vue';
import ChatNotification from '@/components/chat/ChatNotification.vue';
import IndexLayout from '@/components/globals/IndexLayout.vue';
import HeadBar from '@/components/miscellaneous/HeadBar.vue';
import HtMobileMainBar from '@/components/miscellaneous/HtMobileMainBar/index.vue';
import HtMainBar from '@/components/miscellaneous/HtMainBar/index.vue';

export default {
    name: 'Index',
    components: {
        ChatModal,
        ChatNotification,
        IndexLayout,
        HeadBar,
        HtMobileMainBar,
        HtMainBar,
    },

    data: () => ({
        isMobile: false,
    }),

    computed: {
        isCompanySNCF() {
            return this.shared.session.companyUser.company_id === 213;
        },
        isCompanySNCFQA() {
            return this.shared.session.companyUser.company_id === 409;
        },
    },

    watch: {
        isOldUi: {
            handler(value) {
                if (value) {
                    document.body.classList.add('old-ui');
                } else {
                    document.body.classList.remove('old-ui');
                }
            },
            immediate: true,
        },
    },

    mounted() {
        this.onResize();

        window.addEventListener('resize', this.onResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },

    created() {
        // SET R2H2 CHATBOT SNCF
        if (this.isCompanySNCF) {
            const sncfScript = document.createElement('script');
            sncfScript.setAttribute('src', 'https://chatbox.clevy.io/script.min.js?token=271d4368-1e89-425a-ac14-2f508474279c');
            sncfScript.setAttribute('async', '');
            sncfScript.setAttribute('id', 'clevy-chatbox');
            document.head.appendChild(sncfScript);
        }

        if (this.isCompanySNCFQA) {
            const sncfScript = document.createElement('script');
            sncfScript.setAttribute('src', 'https://chatboxv2.clevy.io/script.min.js?token=271d4368-1e89-425a-ac14-2f508474279c');
            sncfScript.setAttribute('async', '');
            sncfScript.setAttribute('id', 'clevy-chatbox');
            document.head.appendChild(sncfScript);
        }
    },

    methods: {
        onResize() {
            this.isMobile = window.innerWidth < 780;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

@media (max-width: $phone) {
    .index-content {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
</style>
