<template>
    <div>
        <Actions v-if="isOldUi" />
        <NewActions v-else />
    </div>
</template>
<script>
import Actions from './Actions.vue';
import NewActions from './NewActions.vue';

export default {
    name: 'ActionsPage',
    components: {
        NewActions,
        Actions,
    },
};
</script>
