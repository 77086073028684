<template>
    <div>
        <template v-if="showValidationActions">
            <HtButton
                size="large"
                type="primary"
                :loading="buttonLoading"
                data-cy="validate-button"
                @click="onClickValidate()"
            >
                <t>Validate</t>
            </HtButton>
            <HtButton
                size="large"
                type="secondary"
                :loading="buttonLoading"
                data-cy="refusal-button"
                @click="onClickRefuse()"
            >
                <t>Refuse</t>
            </HtButton>
        </template>
        <template v-else>
            <HtButton
                v-if="showSave"
                size="large"
                type="primary"
                :loading="buttonLoading"
                :disabled="disableSave"
                data-cy="save-button"
                @click="onClickSave()"
            >
                <t>Save</t>
            </HtButton>
            <HtButton
                v-if="showNotConcerned"
                size="large"
                type="secondary"
                :loading="buttonLoading"
                data-cy="not-concerned-button"
                @click="onClickNotConcerned()"
            >
                <t>Not concerned</t>
            </HtButton>
        </template>
        <HtButton
            v-if="showDelete"
            size="large"
            type="destructive"
            :loading="buttonLoading"
            data-cy="delete-button"
            @click="onClickDelete()"
        >
            <t>Delete</t>
        </HtButton>
    </div>
</template>

<script>
import HtButton from '@/components/miscellaneous/HtButton.vue';

export default {
    name: 'NewModalFooter',
    components: { HtButton },
    props: {
        disableSave: {
            type: Boolean,
            default: false,
        },
        showSave: {
            type: Boolean,
            required: true,
        },
        showDelete: {
            type: Boolean,
            required: true,
        },
        showValidationActions: {
            type: Boolean,
            required: true,
        },
        showNotConcerned: {
            type: Boolean,
            required: true,
        },
        buttonLoading: {
            type: Boolean,
            required: true,
        },
    },

    methods: {
        onClickValidate() {
            this.$emit('on-click-validate');
        },
        onClickRefuse() {
            this.$emit('on-click-refuse');
        },
        onClickSave() {
            this.$emit('on-click-save');
        },
        onClickNotConcerned() {
            this.$emit('on-click-not-concerned');
        },
        onClickDelete() {
            this.$emit('on-click-delete');
        },
    },
};
</script>
